//we are going to use this function to get the description of the rule
//we will pass the rule config object to this function and it will return the description of the rule
// object_label = rule_config.data.label
// object_of_interest_label = rule_config.data.object_of_interest_label
// distance = rule_config.data.distance.amount
// distance_units = rule_config.data.distance.units
// time = rule_config.data.time.amount
// time_units = rule_config.data.time.units
// speed = rule_config.data.speed.limit
// speed_units = rule_config.data.speed.units
// count_limit = rule_config.data.count_limit
const descriptionForOneOrMoreObjects = (rule_config, endText) => {
  if(rule_config){
    const labels = new Set(
      rule_config.config.map(({ data }) => data.label.trim())
    );
    const uniqueLabels = [...labels];
    if (uniqueLabels.length == 1) {
      return `${uniqueLabels[0] + " "} ${endText.trim()}`;
    } else {
      return `${uniqueLabels.join(" or ") + " " + endText.trim()}`;
    }
  }
};

export default function getRuleDescription(rule_config) {
try{
  let rule_type = rule_config.config[0].type;
  let object_label = rule_config.config[0].data?.label;
  let object_of_interest_label = rule_config.config[0].data?.object_of_interest_label;
  let speed_limit = rule_config.config[0].data?.speed?.limit;
  let speed_units = rule_config.config[0].data?.speed?.units;
  let count_limit = rule_config.config[0].data?.count_limit;
  let time_amount = rule_config.config[0].data?.time?.amount;
  let time_units = rule_config.config[0].data?.time?.units;
  let distance_amount = rule_config.config[0].data?.distance?.amount;
  let distance_units = rule_config.config[0].data?.distance?.units;
  switch (rule_type) {
    case 1:
      const description_1 = descriptionForOneOrMoreObjects(
        rule_config,
        "is visible"
      );
      return description_1;
    case 2:
      const description_2 = descriptionForOneOrMoreObjects(
        rule_config,
        "visible in zone"
      );
      return description_2;
    case 3:
      const description_3 = descriptionForOneOrMoreObjects(
        rule_config,
        `travelling faster than ${speed_limit} ${speed_units}`
      );
      return description_3;
    case 4:
      const description_4 = descriptionForOneOrMoreObjects(
        rule_config,
        "moving against the direction of the arrow"
      );
      return description_4;
    case 5:
      const description_5 = descriptionForOneOrMoreObjects(
        rule_config,
        "crossing the line, in any direction"
      );
      return description_5;
    case 6:
      const description_6 = descriptionForOneOrMoreObjects(
        rule_config,
        `visible`
      );
      return `More than ${count_limit} ${description_6}`;

    case 7:
      const description_7 = descriptionForOneOrMoreObjects(
        rule_config,
        `in zone for more than ${time_amount} ${time_units}`
      );
      return description_7;
    case 8:
      const description_8 = descriptionForOneOrMoreObjects(
        rule_config,
        `travelling faster than ${speed_limit} ${speed_units} in zone`
      );
      return description_8;
    case 9:
      const description_9 = descriptionForOneOrMoreObjects(
        rule_config,
        `moves against the direction of the arrow while in the zone`
      );
      return description_9;

    case 10:
      const description_10 = descriptionForOneOrMoreObjects(
        rule_config,
        `visible in zone`
      );
      return `More than ${count_limit} ${description_10}`;

    case 11:
      const description_11 = descriptionForOneOrMoreObjects(
        rule_config,
        `is within ${distance_amount} ${distance_units} of ${object_of_interest_label}`
      );
      return description_11;

    case 12:
      const description_12 = descriptionForOneOrMoreObjects(
        rule_config,
        `is within ${distance_amount} ${distance_units} of the marker`
      );
      return description_12;

    case 13:
      const description_13 = descriptionForOneOrMoreObjects(
        rule_config,
        `travelling faster than ${speed_limit} ${speed_units} while crossing the line in any direction`
      );
      return description_13;

    case 14:
      const description_14 = descriptionForOneOrMoreObjects(
        rule_config,
        `is within ${distance_amount} ${distance_units} of ${object_of_interest_label} while all are inside the zone`
      );
      return description_14;

    case 15:
      const description_15 = descriptionForOneOrMoreObjects(
        rule_config,
        `exists for more than ${time_amount} ${time_units}`
      );
      return description_15;

    case 16:
      return `${object_label} and ${object_of_interest_label} both are in the zone`;
    case 17:
      return `${object_label} and ${object_of_interest_label} both are visible`;
    case 18:
      const description_18 = descriptionForOneOrMoreObjects(
        rule_config,
        `is crossing the line against the direction of the arrow`
      );
      return description_18;

    case 19:
      return ` ${object_label} is within ${distance_amount}  ${distance_units} of ${object_of_interest_label}  while 
            ${object_of_interest_label} is travelling faster than ${speed_limit} ${speed_units}`;
    case 20:
      let sgie_object_wearing_string = rule_config.config[0].data.sgie_objects_interest[0]
        .status
        ? "is wearing"
        : "is not wearing";
      return `${object_label} ${sgie_object_wearing_string} ${rule_config.config[0].data.sgie_objects_interest[0].label}  in zone`;
    case 21:
      return `${object_label} is using a mobile phone in the zone`;
    case 22:
      return `${object_label} is in the zone, bending from their hip, with no bend in legs.`;
    case 23:
      let hand_in_zone_string = rule_config.config[0].data.in_zone
        ? "hand is inside a restriction zone"
        : "hand not holding the marked handrail";
      return `${object_label} is in zone and ${hand_in_zone_string}`;
    case 24:
      return `${object_label} is in zone and reaching with outreached arms at or above shoulder height.`;
    case 25:
      return `${object_label} is within ${distance_amount} ${distance_units} of ${object_of_interest_label}  while 
            ${object_label} is travelling faster than ${speed_limit} ${speed_units}, and both are within zone`;
    case 27:
       return `Any object blocks the zone or zones during event duration.`; // Clearway rule, event will not be visible in the video as it happens over minutes. 
    case 28:
      return `${object_label} crosses the line in the direction of the arrow and does not come to a clear stop at the line.`;
    case 29:
      return `${object_label} is in zone and exhibits any body movement.`;
    case 30:
      return `${object_label} is in zone and exhibits any body movement.`;
    case 31:
      return `The ${object_label} is lying on the ground.`;
    case 35:
      const check_no_sign = rule_config.config[0].data.check_no_sign;
      return `There is a Spill in the zone${check_no_sign? " AND there is no cleaning sign nearby" : ""}.`;
    default:
      return "No definition available";
  }
} catch(e){
    return "No definition available";
  }
}

